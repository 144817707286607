import React from 'react'
import JsonData from './2024.json'
 function JsonDataDisplay(){
    const DisplayData=JsonData.map(
        (info)=>{
            return(
                <tr>
                    <td>{info.Day}</td>
                    <td>{info.DayOfWeek}</td>
                    <td>{info.BringTIme}</td>
                    <td>{info.FetchTime}</td>
                    <td>{info.Name}</td>
                    <td>{info.Info}</td>
                </tr>
            )
        }
    )
 
    return(
        <div>
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>Day</th>
                    <th>DayOfWeek</th>
                    <th>BringTIme</th>
                    <th>FetchTime</th>
                    <th>Name</th>
                    <th>Info</th>
                    </tr>
                </thead>
                <tbody>
                    {DisplayData}
                </tbody>
            </table>
        </div>
    )
 }
 
 export default JsonDataDisplay;