import './App.css';

import JsonDataDisplay from "./Data/JsonDataDisplay";

function App() {
  return (
    <div className="App">
     <h1>Kita 2024</h1>
            <JsonDataDisplay />
    </div>
  );
}

export default App;
